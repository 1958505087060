.drag-div
{
position: absolute;
z-index: 9999;
top:0px;
left:0px;
right: 0px;
height:10px;
-webkit-app-region: drag;
cursor:move;
}