.traffic-light
{
    position: absolute;
    cursor: pointer;
    margin-top:5px;
    margin-left:5px;
    display: flex;
    flex-direction: row;
    z-index:9999;
    > div
    {
        display: flex;
        background-color: #ddd;
        color:#ddd;
        width:12px;
        height:12px;
        margin-right: 8px;
        border-radius: 100%;
        -webkit-app-region: no-drag;
        cursor: pointer;
        font-size: 10px;
        font-family: 'Verdana';
        justify-content: center;
        align-items: center;
    }
}

.traffic-light:hover
{
    > div
    {
        background-color: #aaa; 
        color:white;
    }
}
